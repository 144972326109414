import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';


const Section = styled.section`
  min-height: 100vh;
  height: auto;
  width: 100vw;
  background-color: ${props => props.theme.text};
  position: relative;
  color: ${(props) => props.theme.body};
  overflow: hidden;
  padding: 2rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 90%;
  margin: 2rem auto;
  max-width: 900px;
  display: flex;
  justify-content: center;
  align-content: center;

  @media (max-width: 64em){
    width: 80%;
  }
  @media (max-width: 48em){
    width: 90%;
    flex-direction: column;

    &>*:last-child{
      &>*:first-child{
        margin-top: 0;
      }
    }
  }
`
const Box = styled.div`
  width: 45%;
  @media (max-width: 64em){
    width: 90%;
    align-self: center;
  }
`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {
  
  let element = ref.current;

  ScrollTrigger.create({
    trigger: element,
    start:'bottom bottom',
    end:'bottom top',
    pin:true,   
    pinSpacing:false, 
    scrub:1,
    // markers:true,
  })

  return () => {
    ScrollTrigger.kill();
  };
}, [])

  return (
    <Section ref={ref} id="faq">
      <Title>Faq</Title>

      <Container>

        <Box>
          <Accordion ScrollTrigger={ScrollTrigger} title="When is the expected launch date?" >
            We are still finalizing our roadmap and setting dates for each phase in our roadmap. More info is coming soon...
          </Accordion>

          <Accordion ScrollTrigger={ScrollTrigger} title="Is this an American Company?" >
            Yes, all of our team is based in the US and our farm is located in the West Coast.
          </Accordion>

          <Accordion ScrollTrigger={ScrollTrigger} title="Is this only related to crypto?" >
            We are still finalizing our roadmap and targets but have a few more surprises in store ;)
          </Accordion>

          <Accordion ScrollTrigger={ScrollTrigger} title="How do I learn more?" >
            We are working on adding an email list for anyone interested in staying informed about our progress.
          </Accordion>

          <Accordion ScrollTrigger={ScrollTrigger} title="What happens when I invest?" >
            Investment details are still being finalized. Stay tuned for more info.
          </Accordion>

        </Box>
      </Container>
    </Section>
  )
}

export default Faq