import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Icon from "../assets/Icon.svg"

const LogoImg = styled.img`
  width: 45px;
  height: 45px;
  transition: all 0.2s ease;

  &:hover{
    transform: scale(1.1);
  }
`

const LogoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`

const LogoText = styled.h1`
    font-family: 'Comfortaa Variable', sans-serif;
font-size: ${props => props.theme.fontsm};
color: ${props => props.theme.text};
transition: all 0.2s ease;

&:hover{
    transform: scale(1.1);
}

@media (max-width: 64em){
font-size: ${props => props.theme.fontsm};

}
`

const Logo = () => {
  return (
      <LogoContainer>
          <Link to="/">
              <LogoImg src={Icon}/>
          </Link>
      </LogoContainer>
  )
}

export default Logo