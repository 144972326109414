import React, { lazy, Suspense } from 'react'
import styled, { keyframes } from 'styled-components'
import Loading from "../Loading";

const CoverVideo = lazy(() => import('../CoverVideo'));
const TypeWriterText = lazy(() => import('../TypeWriterText'));

const HomeContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  position: relative;
  background-color: ${props => props.theme.primaryColor};
`
const HomeItems = styled.div`
  width: 75%;
  min-height: 80vh;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;

  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    flex-direction: column;
    width: 100%;
    &>*:first-child{
      width: 100%;
      margin-top: 2rem;
    }
  }
`

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  z-index: 20;
  max-width: 900px;

  @media (min-width: 36em) {
    padding: 28px 25px;
    width: calc(100% - 50px);
  }
  //background-color: blue;
`

const LandingHome = () => {
    return (
        <HomeContainer id="home">
            <HomeItems>
                <TextContainer>
                    <Suspense fallback={<Loading />}>
                        <TypeWriterText /></Suspense>
                </TextContainer>
                <CoverVideo />
            </HomeItems>
        </HomeContainer>
    )
}

export default LandingHome