import React, { lazy, Suspense } from "react";
import styled, { ThemeProvider } from "styled-components";
import Loading from "../Loading";
import {Form} from "react-router-dom";

const Section = styled.section`
  min-height: 70vh;
  width: 100%;
  background-color: ${(props) => props.theme.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
`

const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  /* background-color: lightblue; */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;
    & > *:first-child {
      //width: 80%;
    }
    
  }
  @media (max-width: 40em) {
    & > *:first-child {
      //width: 90%;
    }
  }

  & > *:last-child {
    @media (max-width: 64em) {
      justify-content: flex-start;
    }
    
  }
`

const Box = styled.div`
  width: 50%;
  height: 80%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //background-color: grey;
  max-width: 600px;

  @media (max-width: 40em) {
    min-height: 35vh;
    width: 90%;
  }

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 40em) {
    & > *:first-child {
      //width: 90%;
    }
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;
  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const InputForm = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
  width: 100%;
`

const InputLabel = styled.label`
  font-size: ${(props) => props.theme.fontsm};
  text-transform: uppercase;
  color: rgba(255,255,255, 0.8);

`

const Input = styled.input`
  width: 100%;
  max-width: 360px;
  font-size: ${(props) => props.theme.fontlg};
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid white;
  margin-top: 6px;
  height: 2em;
  
`

const Investors = () => {
    return (
        <Section id="investors">
            <Container>
                <Box>
                    <Title>
                        Invest in <br /> SolarBlock Innovations
                    </Title>
                    <SubText>
                        Interested in being a part of our journey and contributing to our goals?
                    </SubText>
                    <SubText>
                        Fill out the form to receive more info about investment opportunities.
                    </SubText>
                </Box>

                <Box>
                    <InputForm>
                        <InputContainer>
                            <InputLabel>
                                Name
                            </InputLabel>
                            <Input type="text" />
                        </InputContainer>
                        <InputContainer>
                            <InputLabel>
                                Email
                            </InputLabel>
                            <Input type="email" />
                        </InputContainer>

                    </InputForm>
                </Box>


            </Container>
        </Section>
    );
};

export default Investors