import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import Logo from "../assets/White-Logo.png";

const NavBar = styled.nav`
  width: 100vw;
  //background-color: transparent;
  //background-color: red;
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${(props) => `calc(${props.theme.navHeight} + 46px)`};
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
`

const NavItems = styled.div`
  display: grid;
  align-items: center;
  //background-color: blue;
  grid-template-columns: 1fr 1fr;
  padding: 14px 12px;
  width: 100%;
  max-width: 900px;
  
  @media (min-width: 36em) {
    padding: 28px 25px;
    width: calc(100% - 50px);
  }
`
const GridItemPlaceholder = styled.div`
  
`

const LogoContainer = styled.div`
  display: flex;
  //background-color: green;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.theme.navHeight};
  width: auto;
`
const LogoItem = styled.img`
  height: 90%;
  width: auto;
  @media (max-width: 36em) {
    height: 60%;
  }
`

const ButtonContainer = styled.div`
  align-self: center;
  justify-content: end;
  display: flex;
  //background-color: purple;

  @media (min-width: 36em) {
    padding-right: 32px;
  }
  
  @media (max-width: 36em) {
    align-self: center;
    text-align:center;
    //background-color: red;
    a {
      font-size: ${(props) => props.theme.fontxs};
      padding: 0.8rem 1.5rem;
    }

    button{
      margin: 0 auto;
    }
  }
`

const Navigation = () => {
  return (
    <NavBar id="navigation">
      <NavItems>
        {/*<GridItemPlaceholder />*/}
        <LogoContainer>
          <LogoItem src={Logo} />
        </LogoContainer>
        <ButtonContainer>
          <Button text="Investors" link="#investors" />
        </ButtonContainer>
      </NavItems>
    </NavBar>
  );
};

export default Navigation;
